import { NgModule } from '@angular/core';
import { UIModule } from '../../ui.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { LocaleStringPipe } from './pipes/locale-string.pipe';
import { TwoLabelToggleComponent } from './two-lable-toggle/two-label-toggle.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { TabNavigationComponent } from './tab-navigation/tab-navigation.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { ConfirmDeleteDirective } from './confirm-delete.directive';

@NgModule({
  declarations: [
    TwoLabelToggleComponent,
    SearchBarComponent,
    TabNavigationComponent,
    ConfirmDeleteDirective,
  ],
  imports: [
    UIModule,
    TranslateModule,
    CommonModule,
    ReactiveFormsModule,
    RouterLink,
    RouterLinkActive,
    LocaleStringPipe,
  ],
  exports: [
    LocaleStringPipe,
    TwoLabelToggleComponent,
    SearchBarComponent,
    TabNavigationComponent,
    ConfirmDeleteDirective,
  ],
})
export class UtilModule {}
