export const environment = {
  baseUrl: 'https://hpm.qas-bbrauncloud.com/backend',
  selectedLocale: 'de',
  gitHubRefNumber: '822',
  gppURL: 'https://myprofile.test.account.bbraun.com/',
  msalConfig: {
    auth: {
      clientId: '7433c8f0-313d-44f9-8368-f91995b65ebb',
      authority:
        'https://test.account.bbraun.com/dadf93c1-1d2f-487d-a595-5d5428a9ae96/B2C_1A__SIGNUP_SIGNIN_MFA/',
      knownAuthorities: ['https://test.account.bbraun.com/'],
    },
  },
  apiConfig: {
    scopes: ['https://test.account.bbraun.com/auth/gpr/User.Read'],
    uri: 'https://api.myprofile.test.account.bbraun.com/api/me',
  },
  customApiConfig: {
    scopes: [
      'https://test.account.bbraun.com/084582e8-58d6-4e6e-b79f-f8891557e064/read',
    ],
    uri: 'https://hpm.qas-bbrauncloud.com/backend',
  },
};
